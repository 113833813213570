<style lang="less" scoped>
  @import "../../assets/css/variables";

  .label-to4 {
    letter-spacing: 7px;
  }
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>固件更新管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-container clearfix">
      <el-table
        :data="versionList"
        v-loading.body="listLoading"
        @selection-change="selsChange"
        class="list-table"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column align="center" label="序号" width="80">
          <template slot-scope="scope">
            <span>{{ scope.$index + pager.size * (pager.page - 1) + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="name"
          label="固件名"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="swVersion"
          label="版本号"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="firmwareType"
          label="固件类型"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="productTypeName"
          label="产品型号"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="remarks"
          sortable
          label="描述"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="url"
          sortable
          label="链接"
        ></el-table-column>
        <el-table-column label="上传时间" width="160" sortable>
          <template slot-scope="scope">
            {{ formatTime(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <!-- <el-button size="small" @click="handleViewDetail(scope.$index, scope.row)">详情</el-button> -->
            <el-button
              :disabled="!editPermission"
              class="m-l-xs"
              type="danger"
              size="small"
              @click="handleRemove(scope.$index, scope.row.id)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-col :span="24" class="bottom-tool-container">
        <el-button :disabled="false" type="info" @click="versionVisible = true">
          固件上传
        </el-button>
        <el-button
          :disabled="!sels.length > 0 || !editPermission"
          type="danger"
          @click="batchRemove"
        >
          批量删除
        </el-button>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pager.page"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 100]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          class="pull-right"
        ></el-pagination>
      </el-col>
    </div>
    <el-dialog
      :title="isViewDetail ? '版本详情' : '新增版本'"
      size="small"
      :visible.sync="versionVisible"
    >
      <form
        id="versionFileForm"
        class="none"
        method="post"
        enctype="multipart/form-data"
      >
        <input
          type="file"
          id="versionFile"
          @change="selectedApkFile"
          name="upfile"
        />
      </form>
      <el-form
        ref="authForm"
        :rules="rules"
        :model="versionInfo"
        label-width="100px"
      >
        <el-form-item label="固件名称:" prop="name">
          <el-input
            v-if="!isViewDetail"
            v-model="versionInfo.name"
            placeholder="请输入固件名"
          ></el-input>
          <span v-else>{{ versionInfo.name }}</span>
        </el-form-item>
        <el-form-item label="版本号:" prop="swVersion">
          <el-input
            v-if="!isViewDetail"
            v-model="versionInfo.swVersion"
            placeholder="请输入版本号"
          ></el-input>
          <span v-else>{{ versionInfo.swVersion }}</span>
        </el-form-item>

        <el-form-item label="固件类型:" prop="type">
          <el-select
            class="w-full"
            v-if="!isViewDetail"
            v-model="versionInfo.firmwareType"
            placeholder="请选择固件类型"
          >
            <el-option
              v-for="item in [
                { value: 'mcu', label: 'mcu' },
                { value: 'wifi', label: 'wifi' },
              ]"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="更新类型:" prop="type">
          <el-select
            class="w-full"
            v-if="!isViewDetail"
            v-model="versionInfo.type"
            placeholder="请选择更新类型"
          >
            <el-option
              v-for="item in [
                { value: 1, label: '选择更新' },
                { value: 0, label: '强制更新' },
              ]"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>
          <span v-else>{{ versionInfo.type ? "选择更新" : "强制更新" }}</span>
        </el-form-item>
        <el-form-item label="产品型号:" prop="deviceTypeId">
          <el-select
            style="width: 100%"
            v-model="versionInfo.deviceTypeId"
            filterable
            remote
            clearable
            @change="dvcTypeChange"
            :remote-method="queryDvctypes"
            placeholder="选择设备类型"
          >
            <el-option
              v-for="item in dvcTypeList"
              :key="item.id"
              :label="
                `${item.name}（${item.deviceTypeCode + item.productTypeCode}）`
              "
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品牌型号:" prop="productTypeId">
          <el-select
            style="width: 100%"
            v-model="versionInfo.productTypeId"
            filterable
            clearable
            placeholder="选择品牌型号"
          >
            <el-option
              v-for="item in brandTypeList"
              :key="item.id"
              :label="
                `${item.productName}（${item.belongsType +
                  item.belongsSeries}）`
              "
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="isViewDetail" label="创建时间:" prop="createTime">
          {{ formatTime(versionInfo.createTime) }}
        </el-form-item>

        <el-form-item v-if="!isViewDetail" label="版本附件:" prop="zipfile">
          <el-button id="versionFileButton" @click="triggerSelectFile">
            上传附件
          </el-button>
          <span class="m-l-xs">{{ apkFileName }}</span>
        </el-form-item>
        <el-form-item label="版本描述:" prop="remarks">
          <el-input
            v-if="!isViewDetail"
            type="textarea"
            :rows="5"
            :maxlength="200"
            v-model="versionInfo.remarks"
            placeholder="请输入版本的描述"
          ></el-input>
          <span v-else>{{ versionInfo.remarks }}</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-if="!isViewDetail" @click="versionVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="onsubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import moment from "moment";
  import {
    getApkVersions,
    getApkVersionById,
    removeApkVersionById,
    updateApkVersion,
    createApkVersion,
  } from "../../services/setting";
  import { Notification } from "element-ui";
  import { PAGE_SIZE } from "../../config";
  import { mapGetters } from "vuex";
  import {
    searchDevicesByType,
    getBrandTypeByDvcType,
  } from "../../services/device";
  import { valiInput, valiSelect } from "../../utils/validator/element";

  export default {
    data() {
      return {
        editPermission: true,
        isAdmin: false,
        isViewDetail: false, //修改信息 false 新增 true 详情
        loaded: false, //第一次加载状态
        PAGE_SIZE,
        pager: {
          size: PAGE_SIZE,
          page: 1,
        },
        total: 1,
        choosedRowIndex: -1, //修改、删除操作的时候当前选中的行的下标
        versionList: [], //本地分页的数据
        brandTypeList: [],
        filterVersionList: [], //本地过滤后的数据
        dvcTypeList: [], //设备类型列表
        filters: {
          name: "",
        },
        versionInfo: {
          //单个广告版本对象
          name: "", //版本名称
          type: 0, //更新类型 0:选择更新 1:强制更新
          firmwareType: "", // 固件类型: mcu / wifi
          remarks: "",
          deviceTypeId: "",
          swVersion: "",
          productTypeId: "",
        },
        rules: {
          name: [{ validator: valiInput, trigger: "blur" }],
          swVersion: [{ validator: valiInput, trigger: "blur" }],
          deviceTypeId: [{ validator: valiSelect, trigger: "change" }],
          productTypeId: [
            { required: true, message: "请选择", trigger: "change" },
          ],
          remarks: [
            {
              require: false,
              min: 4,
              max: 200,
              message: "描述长度在4到200个字符",
              trigger: "blur",
            },
          ],
        },
        sels: [],
        apkFileName: "",
        listLoading: false, //列表加载完毕状态
        versionVisible: false, //新增广告的view
      };
    },

    computed: {
      ...mapGetters({ user: "userInfo" }),
    },
    methods: {
      dvcTypeChange(val) {
        console.log(val);

        if (val) {
          this.dvcTypeList.forEach((item) => {
            if (item.id === val) {
              this.queryBrandTypeByDvcType(item.productTypeCode);
            }
          });
        }
      },
      //获取品牌列表
      async queryBrandTypeByDvcType(productTypeCode) {
        let responseData = await getBrandTypeByDvcType(productTypeCode, {
          page: 1,
          size: 999,
        });
        if (responseData.errorCode === 0 && responseData.data) {
          this.brandTypeList = responseData.data.list || [];
        }
      },

      /**
       * 获取设备类型列表
       */
      async queryDvctypes(key) {
        // console.log(key)
        const responseData = await searchDevicesByType(
          { key: key },
          { page: 1, size: 9999 }
        );
        if (responseData.errorCode === 0 && responseData.data) {
          this.dvcTypeList = responseData.data.list || [];
        }
        this.loaded = true;
      },
      /**
       * app上传权限
       */
      checkEditPermission() {
        let permissions = this.user && this.user.permissions;
        this.editPermission = permissions.some((item) => {
          return item === "APKVersion:update";
        });
      },
      /**
       * 格式化时间
       */

      formatTime(time) {
        return time ? moment(time).format("YYYY-MM-DD HH:mm:ss") : "";
      },
      // 提交表单
      onsubmit() {
        this.$refs.authForm.validate((valid) => {
          if (valid) {
            this.updateApkVersion();
          }
        });
      },
      /**
       * 单个新增广告
       */
      async updateApkVersion() {
        let responseData = null;
        const $file = document.querySelector("#versionFile");
        if (this.isViewDetail) {
          // responseData = await updateApkVersion(this.versionInfo);
          this.versionVisible = false;
          return;
        } else {
          if (!($file && $file.files.length > 0)) {
            Notification.error({
              title: "错误",
              message: "没有选中的文件",
            });
            return;
          }
          responseData = await createApkVersion(
            $file.files[0],
            this.versionInfo
          );
        }
        if (
          responseData.errorCode === 0 &&
          responseData.data &&
          responseData.data.result &&
          responseData.data.versionInfo
        ) {
          this.versionVisible = false;
          this.queryVersions();
          Notification.success({
            title: "成功",
            message: this.isViewDetail ? "修改固件成功" : "上传固件成功",
          });
        } else {
          Notification.error({
            title: "错误",
            message: responseData.errorMessage,
          });
        }
      },

      searchDataByKey() {
        this.filterVersionList = this.fetchVersionList.filter((item) => {
          return item.versionName.includes(this.filters.name);
        });
        this.queryDirectorys();
      },
      /**
       *
       *
       */
      handleViewDetail(index, versionInfo) {
        this.choosedRowIndex = index;
        this.versionInfo = Object.assign({}, versionInfo);
        this.isViewDetail = true;
        this.versionVisible = true;
      },

      /**
       *  翻页
       */
      handleCurrentChange(page) {
        //        this.pager.page = page;
        //        this.queryVersionsByAdId();
        if (this.loaded) {
          this.pager.page = page;
          window.router.replace({ path: "update", query: { page: page } });
          this.queryVersions();
        }
      },
      /**
       * 设置分页的size
       */
      handleSizeChange(val) {
        this.pager.size = val;
        this.pager.page = 1;
        this.queryVersions();
      },

      /**
       * checkbox勾选的变动的回调
       */
      selsChange: function(sels) {
        this.sels = sels;
      },

      /**
       * 删除
       */
      async handleRemove(index, id) {
        this.$confirm("确认删除选中记录吗？", "提示", {
          type: "warning",
        })
          .then(() => {
            removeApkVersionById(id).then((res) => {
              this.listLoading = false;
              //NProgress.done();
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "删除成功",
                });

                this.queryVersions();
              }
            });
          })
          .catch(() => {});
      },

      /**
       * 批量删除
       */
      batchRemove() {
        const ids = this.sels.map((item) => item.id).toString();
        this.$confirm("确认删除选中记录吗？", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            removeApkVersionById(ids).then((res) => {
              this.listLoading = false;
              //NProgress.done();
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "批量删除成功",
                });

                this.queryVersions();
              }
            });
          })
          .catch(() => {});
      },

      /**
       * 出发系统的file选择
       */
      triggerSelectFile() {
        document.querySelector("#versionFile").click();
      },

      /**
       * 选择了zip的文件
       */
      selectedApkFile($event) {
        if (!this.versionVisible) {
          return;
        }
        this.apkFileName = "";
        let files = $event.target.files;
        let file = null;
        if (files && files.length) {
          file = files[0];
        }
        document.querySelector("#versionFileButton").blur();
        if (file && file.name.includes(".bin")) {
          if (file.size / 1024 / 1024 / 100 > 1) {
            //100M
            Notification.error({
              title: "错误",
              message: "请选择一个小于100M的bin文件",
            });
            return;
          }
          this.apkFileName = file.name;
        } else {
          Notification.error({
            title: "错误",
            message: "请选择一个后缀名为bin的文件",
          });
          return;
        }
      },

      /**
       * 获取所有的广告版本
       */
      async queryVersions() {
        this.listLoading = true;
        const responseData = await getApkVersions(null, this.pager);
        if (responseData.errorCode === 0 && responseData.data) {
          this.versionList = responseData.data.list || [];
          this.total = responseData.data.total;
        }
        this.loaded = true;
        this.listLoading = false;
      },
    },
    async created() {
      // this.isAdmin = this.user.roleCode.indexOf("admin") > -1;

      if (this.$route.query.page) {
        this.pager.page = +this.$route.query.page;
      }
      this.queryVersions();
      //this.checkEditPermission();
    },
    watch: {
      versionVisible(newVal, oldVal) {
        if (newVal === false) {
          setTimeout(() => {
            const $file = document.querySelector("#versionFile");
            $file.value = "";
            this.isViewDetail = false;
            this.apkFileName = "";
            this.versionInfo = {
              versionName: "", //版本名称
              type: 0, //更新类型 0:选择更新 1:强制更新
              firmwareType: "", // 固件类型: mcu / wifi
              remarks: "",
              deviceTypeId: "",
              swVersion: "",
            };
          }, 500);
        } else {
          this.queryDvctypes("");
        }
      },
    },
  };
</script>
